import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function kirimPengaduan(data) {
  console.log(data, "???????????????");
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/tele/kirimPengaduan", data)
    .then(async (respon) => {
      if (respon.status === 200) {
        Swal.fire({
          title: "Terimakasih atas aduannya, akan kami proses!",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title:
            "Maaf pengaduan anda tidak terkirim,periksa koneksi anda dan coba kembali!",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch(errorHandler);
}
