import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  Image,
  Modal,
  ListGroup,
} from "react-bootstrap";
// import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDatetime from "react-datetime";
import logoBAP from "../assets/img/image.jpg";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";

import {
  getKpiMonitoringProblem,
  getKpiMonitoringKeluhan,
  getKpiMonitoringProject,
  getKpiMonitoringKunjungan,
  getKpi1Consumable,
  getKpi1Training,
  getKpi2Pembinaan,
  getKpi1KunjunganPest,
  getKpi2TemuanPest,
  getKpi1ItProject,
  getKpi1Safety,
  getKpi1VisitIssue,
  getKpi2ResponIssue,
  getKpi2Hardware,
  getKpi3Software,
  getKpi3Absensi,
  getKpiMonitoringSurvey,
  updateTglAwal,
  updateTglAkhir,
  getAbsensiDashboard,
  getAllEvent,
  getAllEventCalendar,
} from "../stores";
import moment from "moment";
function Panels() {
  const history = useHistory();
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const consumable = useSelector((state) => state.consumableReducer);
  const trainingPersonnel = useSelector((state) => state.trainingReducer);
  const pestRodent = useSelector((state) => state.pestRodentReducer);
  const projectIt = useSelector((state) => state.itProjectReducer);
  const safety = useSelector((state) => state.safetyReducer);
  const issue = useSelector((state) => state.industrialReducer);
  const survey = useSelector((state) => state.surveyReducer);
  const auth = useSelector((state) => state.authReducer);
  // console.log(auth, "asdasdasdasdasdasdasd");
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(auth.dariTgl);
  const [smpTgl, setSmpTgl] = React.useState(auth.smpTgl);
  const [dariTglToday, setDariTglToday] = React.useState(
    new Date().setHours(1, 0, 0, 0)
  );
  const [smpTglToday, setSmpTglToday] = React.useState(
    new Date().setHours(23, 59, 0, 0)
  );
  const [persenIfm, setPersenIfm] = React.useState("");

  const [filter, setFilter] = React.useState({
    tahun: new Date().getFullYear(),
  });
  const [totalKpi, setTotalKpi] = React.useState("");
  const [modal, setModal] = React.useState(false);

  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth();
  const d = today.getDate();
  const [events, setEvents] = React.useState([]);

  const localizer = momentLocalizer(moment);
  const selectedEvent = (event) => {
    window.alert(event.title);
  };

  const eventColors = (event, start, end, isSelected) => {
    console.log(event.color, "color>>>>>>>>>>>>>");
    var backgroundColor = event.color;
    var textColor = event.text;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      height: "20px",
      color: textColor,
      border: "0px",
      display: "block",
      fontWeight: "bold",
    };

    return {
      style: style,
    };
    // var backgroundColor = "rbc-event-";
    // event.color
    //   ? (backgroundColor = backgroundColor + event.color)
    //   : (backgroundColor = backgroundColor + "default");
    // return {
    //   className: backgroundColor,
    // };
  };
  const hideAlert = () => {
    setAlert(null);
  };

  React.useEffect(() => {
    getAllEvent(dispatch);
    let inter = setInterval(() => {
      getAllEvent(dispatch);
    }, 300000);

    return function cleanup() {
      clearInterval(inter);
    };
  }, []);

  React.useEffect(() => {
    getAllEventCalendar(dispatch);
    let inter = setInterval(() => {
      getAllEventCalendar(dispatch);
    }, 300000);

    return function cleanup() {
      clearInterval(inter);
    };
  }, []);
  React.useEffect(() => {
    getKpiMonitoringProblem(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiMonitoringKeluhan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiMonitoringProject(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiMonitoringKunjungan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Consumable(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Training(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2Pembinaan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1KunjunganPest(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2TemuanPest(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1ItProject(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Safety(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1VisitIssue(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2ResponIssue(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2Hardware(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi3Software(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi3Absensi(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    let inter = setInterval(() => {
      getKpiMonitoringProblem(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpiMonitoringKeluhan(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpiMonitoringProject(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpiMonitoringKunjungan(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi1Consumable(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi1Training(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi2Pembinaan(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi1KunjunganPest(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi2TemuanPest(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi1ItProject(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi1Safety(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi1VisitIssue(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi2ResponIssue(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi2Hardware(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi3Software(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
      getKpi3Absensi(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
      });
    }, 300000);

    return function cleanup() {
      clearInterval(inter);
    };
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getAbsensiDashboard(dispatch, {
      dariTgl: dariTglToday,
      smpTgl: smpTglToday,
    });
    let inter = setInterval(() => {
      getAbsensiDashboard(dispatch, {
        dariTgl: dariTglToday,
        smpTgl: smpTglToday,
      });
    }, 300000);

    return function cleanup() {
      clearInterval(inter);
    };
  }, [dariTglToday, smpTglToday]);

  React.useEffect(() => {
    getKpiMonitoringSurvey(dispatch, filter);
    let inter = setInterval(() => {
      getKpiMonitoringSurvey(dispatch, filter);
    }, 300000);

    return function cleanup() {
      clearInterval(inter);
    };
  }, [filter]);

  React.useEffect(() => {
    monitoring.kpi1 &&
    monitoring.kpi2 &&
    monitoring.kpi4 &&
    monitoring.kpi3 &&
    trainingPersonnel.kpi1 &&
    trainingPersonnel.kpi2 &&
    consumable.kpi1 &&
    pestRodent.kpi1 &&
    pestRodent.kpi2 &&
    projectIt.kpi1 &&
    safety.kpi1 &&
    // safety.kpi1.F2 &&
    // safety.kpi1.FAC2 &&
    // safety.kpi1.LTI2 &&
    // safety.kpi1.MTC2 &&
    // safety.kpi1.NM2 &&
    // safety.kpi1.RW2 &&
    issue.kpi1 &&
    issue.kpi2 &&
    projectIt.kpi2 &&
    projectIt.kpi3 &&
    trainingPersonnel.kpi3
      ? // survey.variablesurvey
        setPersenIfm(
          Math.round(
            (Math.ceil(
              (monitoring.kpi1.pencapaian +
                monitoring.kpi2.pencapaian +
                monitoring.kpi3.pencapaian +
                monitoring.kpi4.pencapaian) /
                4
            ) +
              (consumable.kpi1.pencapaian === "-"
                ? 0
                : consumable.kpi1.pencapaian) +
              Math.ceil(
                (trainingPersonnel.kpi1.pencapaian +
                  trainingPersonnel.kpi2.pencapaian +
                  Math.ceil(trainingPersonnel.kpi3.achievement * 100)) /
                  3
              ) +
              Math.ceil(
                (pestRodent.kpi1.pencapaian + pestRodent.kpi2.pencapaian) / 2
              ) +
              Math.ceil(
                (projectIt.kpi1.pencapaian +
                  projectIt.kpi2.pencapaian +
                  projectIt.kpi3.pencapaian) /
                  3
              ) +
              // Math.ceil(survey.variablesurvey.pencapaian) +
              Math.ceil(
                (safety.kpi1.pencapaian +
                  safety.kpi1.F2 +
                  safety.kpi1.FAC2 +
                  safety.kpi1.LTI2 +
                  safety.kpi1.MTC2 +
                  // safety.kpi1.NM2 +
                  safety.kpi1.RWC2) /
                  6
              ) +
              Math.ceil((issue.kpi1.pencapaian + issue.kpi2.pencapaian) / 2)) /
              (consumable.kpi1.pencapaian === "-" ? 6 : 7)
          )
          // Math.ceil(
          //   (monitoring.kpi1.pencapaian +
          //     monitoring.kpi2.pencapaian +
          //     monitoring.kpi3.pencapaian +
          //     monitoring.kpi4.pencapaian +
          //     consumable.kpi1.pencapaian +
          //     trainingPersonnel.kpi1.pencapaian +
          //     trainingPersonnel.kpi2.pencapaian +
          //     pestRodent.kpi1.pencapaian +
          //     pestRodent.kpi2.pencapaian +
          //     projectIt.kpi1.pencapaian +
          //     safety.kpi1.pencapaian +
          //     safety.kpi1.F2 +
          //     safety.kpi1.FAC2 +
          //     safety.kpi1.LTI2 +
          //     safety.kpi1.MTC2 +
          //     // safety.kpi1.NM2 +
          //     safety.kpi1.RWC2 +
          //     issue.kpi1.pencapaian +
          //     issue.kpi2.pencapaian +
          //     projectIt.kpi2.pencapaian +
          //     projectIt.kpi3.pencapaian +
          //     Math.ceil(trainingPersonnel.kpi3.achievement * 100) +
          //     survey.variablesurvey.pencapaian) /
          //     22
          // )
          // setTotalKpi(
          //   monitoring.kpi1.pencapaian +
          //     monitoring.kpi2.pencapaian +
          //     monitoring.kpi3.pencapaian +
          //     monitoring.kpi4.pencapaian +
          //     consumable.kpi1.pencapaian +
          //     trainingPersonnel.kpi1.pencapaian +
          //     trainingPersonnel.kpi2.pencapaian +
          //     pestRodent.kpi1.pencapaian +
          //     pestRodent.kpi2.pencapaian +
          //     projectIt.kpi1.pencapaian +
          //     safety.kpi1.pencapaian +
          //     issue.kpi1.pencapaian +
          //     issue.kpi2.pencapaian +
          //     projectIt.kpi2.pencapaian +
          //     projectIt.kpi3.pencapaian +
          //     trainingPersonnel.kpi3.pencapaian +
          //     survey.variablesurvey.pencapaian
          // )
        )
      : setPersenIfm("100");
  });
  // console.log(
  //   monitoring.kpi1.pencapaian,
  //   monitoring.kpi2.pencapaian,
  //   monitoring.kpi3.pencapaian,
  //   monitoring.kpi4.pencapaian,
  //   consumable.kpi1.pencapaian,
  //   trainingPersonnel.kpi1.pencapaian,
  //   trainingPersonnel.kpi2.pencapaian,
  //   pestRodent.kpi1.pencapaian,
  //   pestRodent.kpi2.pencapaian,
  //   projectIt.kpi1.pencapaian,
  //   safety.kpi1.pencapaian,
  //   safety.kpi1.F2,
  //   safety.kpi1.FAC2,
  //   safety.kpi1.LTI2,
  //   safety.kpi1.MTC2,
  //   // safety.kpi1.NM2 ,
  //   safety.kpi1.RWC2,
  //   issue.kpi1.pencapaian,
  //   issue.kpi2.pencapaian,
  //   projectIt.kpi2.pencapaian,
  //   projectIt.kpi3.pencapaian,
  //   Math.ceil(trainingPersonnel.kpi3.achievement * 100),
  //   survey.variablesurvey.pencapaian
  // );

  React.useEffect(() => {
    let tmp =
      trainingPersonnel.allEventCalendar &&
      trainingPersonnel.allEventCalendar.map((val, index) => {
        return {
          title: val.title,
          allDay: val.allDay,
          start: new Date(val.start),
          end: new Date(val.end),
          color: val.color,
          text: val.text,
        };
      });
    setEvents(tmp);
  }, [trainingPersonnel.allEventCalendar]);
  // console.log(
  //   trainingPersonnel.allEvent && trainingPersonnel.allEvent.length == 0,
  //   ">>>>>>>>>kegiatan"
  // );
  return (
    <>
      <Container fluid>
        {/* <Row>
          <Col className="ml-auto" md="3">
            <div>
              <Image
                src="https://mdbcdn.b-cdn.net/img/new/slides/041.jpg"
                style={{
                  width: 200,
                  height: 250,
                  margin: 20,
                }}
                thumbnail
              />
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col md="12">
            <Card
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <Card.Header
                className="d-none d-md-inline"
                style={{ backgroundColor: "white" }}
              >
                <Card.Title as="h2">
                  <Row>
                    <Col md="8" className="p-3">
                      <b>IFM Performance Achievements</b>
                    </Col>
                    <Col md="4" className="p-3 d-flex justify-content-end">
                      <Image
                        src={
                          "https://backoffice.bapguard.com/upload/logo_bap.png"
                        }
                        width="auto"
                        height="50px"
                      />
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header>
              <Card.Body style={{ backgroundColor: "#04293A" }}>
                <Row>
                  <Col
                    lg="5"
                    className="d-none d-lg-flex align-items-center justify-content-center"
                  >
                    <i
                      className="fas fa-users-cog fa-5x"
                      style={{ color: "white" }}
                    ></i>
                  </Col>
                  <Col lg="7" xs="12">
                    <div className="numbers d-flex justify-content-around">
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b style={{ color: "white" }}>Achievement</b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{
                            color: persenIfm < 89 ? "#CD1818" : "#20c400",
                            fontFamily: "sans-serif",
                          }}
                        >
                          <b>
                            {/* {monitoring.kpi1 &&
                            monitoring.kpi2 &&
                            monitoring.kpi4 &&
                            monitoring.kpi3 &&
                            trainingPersonnel.kpi1 &&
                            trainingPersonnel.kpi2 &&
                            consumable.kpi1 &&
                            pestRodent.kpi1 &&
                            pestRodent.kpi2 &&
                            projectIt.kpi1 &&
                            safety.kpi1 &&
                            issue.kpi1 &&
                            issue.kpi2 &&
                            projectIt.kpi2 &&
                            projectIt.kpi3 &&
                            trainingPersonnel.kpi3 &&
                            survey.variablesurvey
                              ? Math.ceil(
                                  (monitoring.kpi1.pencapaian +
                                    monitoring.kpi2.pencapaian +
                                    monitoring.kpi3.pencapaian +
                                    monitoring.kpi4.pencapaian +
                                    consumable.kpi1.pencapaian +
                                    trainingPersonnel.kpi1.pencapaian +
                                    trainingPersonnel.kpi2.pencapaian +
                                    pestRodent.kpi1.pencapaian +
                                    pestRodent.kpi2.pencapaian +
                                    projectIt.kpi1.pencapaian +
                                    safety.kpi1.pencapaian +
                                    issue.kpi1.pencapaian +
                                    issue.kpi2.pencapaian +
                                    projectIt.kpi2.pencapaian +
                                    projectIt.kpi3.pencapaian +
                                    trainingPersonnel.kpi3.pencapaian +
                                    survey.variablesurvey.pencapaian) /
                                    17
                                )
                              : "100"}{" "} */}
                            {persenIfm}%
                          </b>
                        </Card.Title>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b style={{ color: "white" }}>Target</b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{ color: "#20c400", fontFamily: "sans-serif" }}
                        >
                          <b>89%</b>
                        </Card.Title>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              {/* <Card.Footer>
                <hr></hr>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <Button
                      onClick={() => {
                        history.push("/admin/detailMonitoring");
                      }}
                      className="btn-wd btn-outline mr-1"
                      type="button"
                      variant="info"
                    >
                      Detail
                    </Button>
                  </Col>
                </Row>
              </Card.Footer> */}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="6">
            <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
              Today's Attendance
            </h4>
            <Row>
              <Col lg="4" sm="6">
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{ backgroundColor: "#34cceb" }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b>Total Employees</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {trainingPersonnel.absensiDashboard &&
                      trainingPersonnel.absensiDashboard.data[0].Total}{" "}
                    people
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{ backgroundColor: "#34cceb" }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b>Total Present</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {trainingPersonnel.absensiDashboard &&
                      trainingPersonnel.absensiDashboard.data[0].Masuk}{" "}
                    people
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{ backgroundColor: "#34cceb" }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b>Total Sick</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {trainingPersonnel.absensiDashboard &&
                      trainingPersonnel.absensiDashboard.data[0].Sakit}{" "}
                    people
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{ backgroundColor: "#34cceb" }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b>Total Paid Leave</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {trainingPersonnel.absensiDashboard &&
                      trainingPersonnel.absensiDashboard.data[0].Cuti}{" "}
                    people
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{ backgroundColor: "#34cceb" }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b>Total Permission</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {trainingPersonnel.absensiDashboard &&
                      trainingPersonnel.absensiDashboard.data[0].Ijin}{" "}
                    people
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{ backgroundColor: "#34cceb" }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b>Total Absent</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {trainingPersonnel.absensiDashboard &&
                      trainingPersonnel.absensiDashboard.data[0].Alpha}{" "}
                    people
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg="6" sm="6">
            <Row>
              <Col lg="5" sm="4">
                <h4
                  className="title mt-2"
                  style={{ marginLeft: 30, fontWeight: "bold" }}
                >
                  Event Calendar
                </h4>
              </Col>
              <Col>
                {" "}
                <Button
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    // color: "#000000",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    setModal(true);
                  }}
                  className="btn-wd  mr-1"
                  type="button"
                  variant="info"
                >
                  Show Calendar
                </Button>
              </Col>
            </Row>

            <Container
              style={{
                height: 250,
                // backgroundColor: "#e8e8e8",
                overflow: "scroll",
                padding: 15,
                paddingLeft: 30,
                // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              {trainingPersonnel.allEvent &&
              trainingPersonnel.allEvent.length == 0 ? (
                <Container
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: 10,
                    marginTop: 15,
                    padding: 15,
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <p style={{ fontWeight: "bold" }}>No event today</p>
                </Container>
              ) : (
                trainingPersonnel.allEvent &&
                trainingPersonnel.allEvent.map((val) => {
                  return (
                    <Container
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: 10,
                        marginTop: 15,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    >
                      <Row>
                        <Col
                          className="d-none d-lg-flex align-items-center justify-content-center"
                          md="2"
                          style={{
                            backgroundColor: val.backgroundColor,
                            borderTopLeftRadius: 10,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 45,
                              fontFamily: "inherit",
                              color: val.textColor,
                            }}
                          >
                            {val.indikator}
                          </div>
                        </Col>
                        <Col style={{ padding: 0 }}>
                          {/* <Card> */}
                          <Card.Header>
                            <Card.Title>
                              {val.kategori} : {val.judul}
                            </Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col sm={3}>Start</Col>
                              <Col sm={1}>:</Col>
                              <Col sm={8}>
                                {moment(val.startDate).format(
                                  "DD MMM YYYY / h:mm:ss a"
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={3}>Deadline</Col>
                              <Col sm={1}>:</Col>
                              <Col sm={8}>
                                {moment(val.deadlineDate).format(
                                  "DD MMM YYYY / h:mm:ss a"
                                )}
                              </Col>
                            </Row>
                          </Card.Body>
                          {/* </Card> */}
                        </Col>
                      </Row>
                    </Container>
                  );
                })
              )}
            </Container>
          </Col>
        </Row>
        <hr style={{ borderWidth: 2 }}></hr>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
              Start Date
            </h4>
            <Form.Group
              style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            >
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAwal(dispatch, {
                    dariTgl: e.toDate().setHours(0, 0, 0, 0),
                  });
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
              End Date
            </h4>
            <Form.Group
              style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            >
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  updateTglAkhir(dispatch, {
                    smpTgl: e.toDate().setHours(23, 59, 0, 0),
                  });
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {auth.username === "admin" ? (
            <Col md="3">
              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Card.Header>
                  <Card.Title as="h4">Cleaning Service</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-tv text-warning fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {monitoring.kpi1 &&
                            monitoring.kpi2 &&
                            monitoring.kpi4 &&
                            monitoring.kpi3
                              ? Math.ceil(
                                  (monitoring.kpi1.pencapaian +
                                    monitoring.kpi2.pencapaian +
                                    monitoring.kpi3.pencapaian +
                                    monitoring.kpi4.pencapaian) /
                                    4
                                )
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5">100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/detailMonitoring");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}
          {auth.username === "admin" || auth.username === "Logistik JTI" ? (
            <Col md="3">
              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Card.Header>
                  <Card.Title as="h4">Consumable Supply</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-box-open text-primary fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {consumable.kpi1
                              ? consumable.kpi1.pencapaian
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5">
                            {" "}
                            {consumable.kpi1 ? consumable.kpi1.target : "100"} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/variableconsumable");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}
          {auth.username === "admin" || auth.username === "HR JTI" ? (
            <Col md="3">
              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Card.Header>
                  <Card.Title as="h4">Training and Personnel</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-user-tie fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {trainingPersonnel.kpi1 &&
                            trainingPersonnel.kpi2 &&
                            trainingPersonnel.kpi3
                              ? Math.ceil(
                                  (trainingPersonnel.kpi1.pencapaian +
                                    trainingPersonnel.kpi2.pencapaian +
                                    Math.ceil(
                                      trainingPersonnel.kpi3.achievement * 100
                                    )) /
                                    3
                                )
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5">
                            {trainingPersonnel.kpi1 && trainingPersonnel.kpi2
                              ? Math.ceil(
                                  (trainingPersonnel.kpi1.target +
                                    trainingPersonnel.kpi2.target) /
                                    2
                                )
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartTraining");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}

          {auth.username === "admin" ? (
            <Col md="3">
              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Card.Header>
                  <Card.Title as="h4">Pest and Rodent</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-cat text-danger fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {pestRodent.kpi1 && pestRodent.kpi2
                              ? Math.ceil(
                                  (pestRodent.kpi1.pencapaian +
                                    pestRodent.kpi2.pencapaian) /
                                    2
                                )
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5"> 100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartPest");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}
        </Row>
        <Row>
          {auth.username === "admin" ? (
            <Col md="3">
              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Card.Header>
                  <Card.Title as="h4">IT Improvement</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-mobile-alt text-primary fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {projectIt.kpi1 && projectIt.kpi2 && projectIt.kpi3
                              ? Math.ceil(
                                  (projectIt.kpi1.pencapaian +
                                    projectIt.kpi2.pencapaian +
                                    projectIt.kpi3.pencapaian) /
                                    3
                                )
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5"> 100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartIT");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}
          {auth.username === "admin" ? (
            <Col md="3">
              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Card.Header>
                  <Card.Title as="h4">Customer Satisfaction</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-chart-line text-success fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {survey.variablesurvey
                              ? Math.ceil(survey.variablesurvey.pencapaian)
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5"> 90 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/variable-survey");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}
          {auth.username === "admin" ? (
            <Col md="3">
              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Card.Header>
                  <Card.Title as="h4">Safety</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-hard-hat text-warning fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {safety.kpi1
                              ? Math.ceil(
                                  (safety.kpi1.pencapaian +
                                    safety.kpi1.F2 +
                                    safety.kpi1.FAC2 +
                                    safety.kpi1.LTI2 +
                                    safety.kpi1.MTC2 +
                                    // safety.kpi1.NM2 +
                                    safety.kpi1.RWC2) /
                                    6
                                )
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5"> 100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartSafety");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}
          {auth.username === "admin" ? (
            <Col md="3">
              <Card style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Card.Header>
                  <Card.Title as="h4">Industrial Issue</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-industry fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {issue.kpi1 && issue.kpi2
                              ? Math.ceil(
                                  (issue.kpi1.pencapaian +
                                    issue.kpi2.pencapaian) /
                                    2
                                )
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5"> 100 %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartIssue");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}
        </Row>
        <Row>
          {auth.username === "Keuangan BAP" ||
          auth.username === "Logistik BAP" ? (
            <Col md="3">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Consumable Supply BAP</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs="3">
                      <div className="text-center">
                        <i className="fas fa-box-open text-primary fa-2x"></i>
                      </div>
                    </Col>
                    <Col xs="8">
                      <div className="numbers d-flex justify-content-around">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h5">
                            {consumable.kpi1
                              ? consumable.kpi1.pencapaian
                              : "100"}{" "}
                            %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Target</p>
                          <Card.Title as="h5">
                            {" "}
                            {consumable.kpi1 ? consumable.kpi1.target : "100"} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/variableconsumablebap");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          ) : null}
        </Row>
      </Container>

      <Modal
        size="xl"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <BigCalendar
            //   selectable
            localizer={localizer}
            events={events}
            defaultView="month"
            // scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date()}
            // onSelectEvent={(event) => selectedEvent(event)}
            // onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
            eventPropGetter={eventColors}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Panels;
