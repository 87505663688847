import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reduxSoldierMiddleware } from "redux-soldier";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import monitoringReducer from "./monitoring";
import surveyReducer from "./survey";
import trainingReducer from "./training";
import pestRodentReducer from "./pestRodent";
import safetyReducer from "./safety";
import industrialReducer from "./industrialIssue";
import itProjectReducer from "./itProject";
import consumableReducer from "./consumable";
import kantinReducer from "./menuKantin";
import gedungChecklistReducer from "./gedungchecklist";
// import pengaduanReducer from "./pengaduan";
// function
export * from "./auth/function";
export * from "./monitoring/function";
export * from "./training/function";
export * from "./pestRodent/function";
export * from "./errorHandler";
export * from "./survey/function";
export * from "./safety/function";
export * from "./industrialIssue/function";
export * from "./itProject/function";
export * from "./consumable/function";
export * from "./menuKantin/function";
export * from "./gedungchecklist/function";
export * from "./pengaduan/function";
// server api
// export const baseUrl = process.env.REACT_APP_URL;
// export const baseUrl = "https://apijti.ptbap.net/";
// export const baseUrl = "http://192.168.110.242:9030/";
// export const baseUrl = "http://10.5.50.134:9030/";
// export const baseUrl = "https://newapiifm.ptbap.net/";
export const baseUrl = "https://apitelegram.ptbap.net/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  timeout: 1000000,
});

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  monitoringReducer,
  surveyReducer,
  trainingReducer,
  pestRodentReducer,
  safetyReducer,
  industrialReducer,
  itProjectReducer,
  consumableReducer,
  kantinReducer,
  gedungChecklistReducer,
  // pengaduanReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxSoldierMiddleware))
);
