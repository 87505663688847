import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { kirimPengaduan } from "../../stores";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Pengaduan() {
  const dispatch = useDispatch();
  //   const survey = useSelector((state) => state.surveyReducer);
  //   const [pertanyaanArr, setPertanyaanArr] = React.useState([]);
  const [jenisPengaduan, setJenisPengaduan] = React.useState("");
  const [keterangan, setKeterangan] = React.useState("");

  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="survey-form">
          <Card style={{ backgroundColor: "white" }}>
            <Card.Header>
              <Card.Title as="h4">Pengaduan</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <Form.Group>
                    <label>Jenis Pengaduan</label>
                    <Form.Control
                      onChange={(e) => {
                        setJenisPengaduan(e.target.value);
                      }}
                      type="text"
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col sm="12">
                  <Form.Group>
                    <label>Keterangan</label>
                    <Form.Control
                      onChange={(e) => {
                        setKeterangan(e.target.value);
                        // setDataGedung({
                        //   ...dataGedung,
                        //   namaRuangan: e.target.value,
                        // });
                      }}
                      //   disabled={survey.statusUser ? true : false}
                      as="textarea"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              {/* {survey.statusUser ? null : ( */}
              <Button
                className="btn-fill pull-right"
                type="submit"
                variant="info"
                onClick={(e) => {
                  e.preventDefault();
                  kirimPengaduan({
                    keterangan,
                    jenisPengaduan,
                  });
                }}
              >
                Submit
              </Button>
              {/* )} */}
            </Card.Body>
          </Card>
        </div>

        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default Pengaduan;
